import { React, useState } from 'react';
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import SideNav from "../../../components/SideNav";
import Footer from "../../../components/Footer";
import DocumentTitle from '../../../functions/setDocumentTitle';
import ContractList from '../../../components/contract/list_Contract';
import ContractCreate from "../../../components/contract/popup_create";
import ContractDetail from "../../../components/contract/popup_details";

const ContractsList = () => {

  const [modalContractCreate, setModalContractCreate] = useState(false);

  const [modalDetail, setModalDetail] = useState({
    show: false,
    id: 0,
  });

  const [refreshComponents, setRefreshComponents] = useState({
    contractList: ''
  });
 
  return (
    <>
      {DocumentTitle("Contratos")}
      <Header />
      <SideNav activeItem={4} />
      <ContractCreate
        data={{
          CustomerId: null,
          CustomerName: null,
          CustomerReference: null,
        }}
        show={modalContractCreate}
        onHide={() => setModalContractCreate(false)}
        onSave={(proposalId) => {
          setRefreshComponents({ proposalList: new Date() });
          setModalContractCreate(false);
          setModalDetail({ show: true, id: proposalId });
        }}
      />
      <ContractDetail
        id={modalDetail.id}
        show={modalDetail.show}
        onHide={() => setModalDetail({ show: false, id: 0 })}
        onSave={() => {
          setRefreshComponents({ contractList: new Date() });
          setModalDetail({ show: false, id: 0 });
        }}
      />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Contratos</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard/main">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Contratos</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="mb-3 text-right">
            <button className="btn btn-primary btn-l" onClick={() => setModalContractCreate(true)} >
              <i className="fas fa-plus pr-2"></i>
                Criar Contrato
            </button>
          </div>
          {/* Default box */}
          <div className="card">
            <div className="card-body p-0">
              <ContractList 
                pageSize={50}
                refreshControl={refreshComponents.contractList}
              />
            </div>
            {/* /.card-body */}

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
    </>
  );
}

export default ContractsList
