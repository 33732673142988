import { React, useState, useEffect } from "react";
import { fetchAPI } from "../../functions/fetchAPI";
import SnackbarAlert from "../SnackbarAlert";
import PopUpLoading from "../PopUpLoading";
import { Modal, Form } from "react-bootstrap";
import getLocalData from "../../functions/getLocalData";

const ProposalCreate = (props) => {
  const [loading, setLoading] = useState({
    isLoading: false,
    message: "",
  });

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    alert: "",
  });

  const [modalShow, setModalShow] = useState(false);

  const [form_Data, setFormData] = useState({
    Proposal_SentDate: "",
    Proposal_ProposalDate: "",
    Proposal_ProposalExpirationDays: "",
    Proposal_Value: "",
    Proposal_ContractDurationYears: "",
    Proposal_SpecialConditions: "",
    Proposal_CreatedAt: "",
    Proposal_UpdatedAt: "",
    Proposal_CustomerId: props.data.CustomerId,
    Proposal_ContractTypeId: "",
    Proposal_StatusId: "",
    ProposalStatus_Code: "",
    Elevators: [],
  });

  const [customerList, setCustomer] = useState([
    {
      Customer_Id: "",
      Customer_Reference: "",
      Customer_Name: "",
    },
  ]);

  const [contractTypeList, setContractType] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  const [statusList, setStatus] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  async function getInitData(customerId) {
    setLoading({
      isLoading: true,
      message: "A obter dados ...",
    });
    try {
      if (!customerId) {
        const fetchCustomers = await fetchAPI(
          "/api.php/customer/list?tenant=" + getLocalData("tenant", "id"),
          "GET"
        );
        const resCustomers = await fetchCustomers;
        setCustomer(resCustomers);
      }

      const fetchContractTypes = await fetchAPI(
        "/api.php/system/contracttype/list?tenant=" +
          getLocalData("tenant", "id"),
        "GET"
      );
      const fetchStatus = await fetchAPI("/api.php/proposal/listStatus", "GET");
      const resContractTypes = await fetchContractTypes;
      const resStatus = await fetchStatus;
      setContractType(resContractTypes);
      setStatus(resStatus);

      const filterStatus = resStatus.filter((status) =>
        status.Code.includes("NEW")
      );
      setFormData({
        ...form_Data,
        Proposal_StatusId: filterStatus[0].Id,
        ProposalStatus_Code: filterStatus[0].Code,
      });
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
        type: "error",
      });
    }

    setLoading({
      isLoading: false,
      message: "",
    });
  }

  async function createProposal() {
    setLoading({
      isLoading: true,
      message: "A adicionar a proposta ...",
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchData = await fetchAPI("/api.php/proposal/create", "POST", {
        Proposal: {
          SentDate: form_Data.Proposal_SentDate,
          ProposalDate: form_Data.Proposal_ProposalDate,
          ProposalExpirationDays: form_Data.Proposal_ProposalExpirationDays,
          Value: form_Data.Proposal_Value,
          ContractDurationYears: form_Data.Proposal_ContractDurationYears,
          SpecialConditions: form_Data.Proposal_SpecialConditions,
          ContractTypeId: form_Data.Proposal_ContractTypeId,
          ProposalStatusId: form_Data.Proposal_StatusId,
          CustomerId: form_Data.Proposal_CustomerId,
          TenantId: getLocalData("tenant", "id"),
        },
      });
      const res = await fetchData;
      setAlert({
        open: true,
        message: res.message,
        type: res.status,
      });
      if (res.status !== "error") {
        props.onSave(res.Id);
      } else {
        setLoading({
          isLoading: false,
        });
      }
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
        type: "error",
      });
      setLoading({
        isLoading: false,
      });
    }
    set_Validated(false);
  }

  useEffect(() => {

    if (props.show) {
      setModalShow(true);
      getInitData(props.data.CustomerId);
    } else {
      setModalShow(false);
    }

    setFormData({
      Proposal_SentDate: "",
      Proposal_ProposalDate: "",
      Proposal_ProposalExpirationDays: "",
      Proposal_Value: "",
      Proposal_ContractDurationYears: "",
      Proposal_SpecialConditions: "",
      Proposal_CreatedAt: "",
      Proposal_UpdatedAt: "",
      Proposal_CustomerId: props.data.CustomerId,
      Proposal_ContractTypeId: "",
      Proposal_StatusId: "",
      ProposalStatus_Code: "",
      Elevators: [],
    });

  }, [props]);

  const [validated, set_Validated] = useState(false);

  const submitFn = (event) => {
    const form = event.currentTarget;

    set_Validated(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      createProposal();
    }
  };

  const handleOnChange = (event) => {
    const { id, value, selectedIndex } = event.target;

    if (id === "Proposal_StatusId" && value) {
      setFormData({
        ...form_Data,
        [id]: value,
        ProposalStatus_Code: statusList[selectedIndex - 1].Code,
      });
    } else {
      setFormData({
        ...form_Data,
        [id]: value,
      });
    }
  };

  const SentDate = () => (
    <>
      <label htmlFor="SentDate" className="required">
        Data de Envio
      </label>
      <Form.Control
        type="date"
        name="SentDate"
        className="form-control"
        id="Proposal_SentDate"
        value={form_Data.Proposal_SentDate}
        onChange={handleOnChange}
        required={true}
        isInvalid={!form_Data.Proposal_SentDate && validated}
      />
      <Form.Control.Feedback type="invalid">
        A Data de Envio é obrigatória!
      </Form.Control.Feedback>
    </>
  );

  return (
    <>
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => {
          props.onHide();
          setModalShow(false);
        }}
        backdrop="static"
      >
        <PopUpLoading open={loading.isLoading} />
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Adicionar Proposta
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid pb-4">
            <Form
              noValidate
              method="post"
              onSubmit={submitFn}
              id="proposalForm"
            >
              <div className="row">
                <div className="col-6">
                  <label
                    htmlFor="CustomersSelect"
                    className={!props.data.CustomerId ? "required" : null}
                  >
                    Cliente
                  </label>
                  {props.data.CustomerId ? (
                    <div>
                      {props.data.CustomerReference +
                        " - " +
                        props.data.CustomerName}
                    </div>
                  ) : (
                    <>
                      <Form.Select
                        type="select"
                        as="select"
                        name="CustomersSelect"
                        onChange={handleOnChange}
                        id="Proposal_CustomerId"
                        required={true}
                        isInvalid={!form_Data.Proposal_CustomerId && validated}
                      >
                        <option value="">Escolher Cliente</option>
                        {customerList.map((item, index) => (
                          <option key={index} value={item.Customer_Id}>
                            {item.Customer_Reference +
                              " - " +
                              item.Customer_Name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        O Cliente é obrigatório!
                      </Form.Control.Feedback>
                    </>
                  )}
                </div>
                <div className="col-6"></div>
              </div>
              <div className="row mt-4">
                <div className="col-4">
                  <label htmlFor="ContractTypeSelect" className="required">
                    Tipo de Contrato
                  </label>
                  <Form.Select
                    type="select"
                    as="select"
                    name="ContractTypeSelect"
                    onChange={handleOnChange}
                    value={form_Data.Proposal_ContractTypeId}
                    id="Proposal_ContractTypeId"
                    required={true}
                    isInvalid={!form_Data.Proposal_ContractTypeId && validated}
                  >
                    <option value="">Escolher Tipo de Contrato</option>
                    {contractTypeList.map((item, index) => (
                      <option key={index} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    O Tipo de Contrato é obrigatório!
                  </Form.Control.Feedback>
                </div>
                <div className="col-4">
                  <label htmlFor="StatusId" className="required">
                    Estado
                  </label>
                  <Form.Select
                    type="select"
                    as="select"
                    name="StatusId"
                    onChange={handleOnChange}
                    value={form_Data.Proposal_StatusId}
                    id="Proposal_StatusId"
                    required={true}
                    isInvalid={!form_Data.Proposal_StatusId && validated}
                  >
                    <option value="">Escolher Estado</option>
                    {statusList.map((item, index) => (
                      <option key={index} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    O Estado é obrigatório!
                  </Form.Control.Feedback>
                </div>
                <div className="col-4">
                  {form_Data.ProposalStatus_Code !== "NEW" ? (
                    <SentDate />
                  ) : null}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-3">
                  <label htmlFor="ProposalDate" className="required">
                    Data da Proposta
                  </label>
                  <Form.Control
                    type="date"
                    name="ProposalDate"
                    className="form-control"
                    id="Proposal_ProposalDate"
                    value={form_Data.Proposal_ProposalDate}
                    onChange={handleOnChange}
                    required={true}
                    isInvalid={!form_Data.Proposal_ProposalDate && validated}
                  />
                  <Form.Control.Feedback type="invalid">
                    A Data da Proposta é obrigatória!
                  </Form.Control.Feedback>
                </div>
                <div className="col-3">
                  <label htmlFor="ProposalExpirationDays" className="required">
                    Duração da Proposta (dias)
                  </label>
                  <Form.Control
                    type="number"
                    name="ProposalExpirationDays"
                    className="form-control"
                    id="Proposal_ProposalExpirationDays"
                    value={form_Data.Proposal_ProposalExpirationDays}
                    onChange={handleOnChange}
                    required={true}
                    isInvalid={
                      !form_Data.Proposal_ProposalExpirationDays && validated
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    A Duração da Proposta é obrigatória!
                  </Form.Control.Feedback>
                </div>
                <div className="col-3">
                  <label htmlFor="Value" className="required">
                    Prestação Mensal
                  </label>
                  <Form.Control
                    type="number"
                    name="Value"
                    className="form-control"
                    id="Proposal_Value"
                    value={form_Data.Proposal_Value}
                    onChange={handleOnChange}
                    required={true}
                    isInvalid={!form_Data.Proposal_Value && validated}
                  />
                  <Form.Control.Feedback type="invalid">
                    A Prestação Mensal é obrigatória!
                  </Form.Control.Feedback>
                </div>
                <div className="col-3">
                  <label htmlFor="ContractDurationYears" className="required">
                    Duração do Contrato (anos)
                  </label>
                  <Form.Control
                    type="number"
                    name="ContractDurationYears"
                    className="form-control"
                    id="Proposal_ContractDurationYears"
                    value={form_Data.Proposal_ContractDurationYears}
                    onChange={handleOnChange}
                    required={true}
                    isInvalid={
                      !form_Data.Proposal_ContractDurationYears && validated
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    A Duração do Contrato é obrigatória!
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <label htmlFor="SpecialConditions">Condições Especiais</label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="SpecialConditions"
                    className="form-control"
                    id="Proposal_SpecialConditions"
                    value={form_Data.Proposal_SpecialConditions}
                    onChange={handleOnChange}
                  />
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary mr-auto"
            type="submit"
            form="proposalForm"
          >
            Adicionar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProposalCreate;
