import { React, useState } from 'react';
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import SideNav from "../../../components/SideNav";
import Footer from "../../../components/Footer";
import DocumentTitle from '../../../functions/setDocumentTitle';
import ElevatorList from '../../../components/elevator/list_Elevator';
import ElevatorCreate from "../../../components/elevator/popup_create";

const ElevatorsList = () => {

  const [modalElevatorCreate, setModalElevatorCreate] = useState(false);

  const [refreshComponents, setRefreshComponents] = useState({
    elevatorList: ''
  });
 
  return (
    <>
      {DocumentTitle("Elevadores")}
      <Header />
      <SideNav activeItem={6} />
      <ElevatorCreate
        data={{
          CustomerId: null,
          CustomerName: null,
          CustomerReference: null,
        }}
        show={modalElevatorCreate}
        onHide={() => setModalElevatorCreate(false)}
        onSave={() => {
          setRefreshComponents({ elevatorList: new Date() });
          setModalElevatorCreate(false);
        }}
      />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Elevadores</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard/main">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Elevadores</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="mb-3 text-right">
            <button className="btn btn-primary btn-l" onClick={() => setModalElevatorCreate(true)} >
              <i className="fas fa-plus pr-2"></i>
                Criar Elevador
            </button>
          </div>
          {/* Default box */}
          <div className="card">
            <div className="card-body p-0">
              <ElevatorList 
                pageSize={50}
                refreshControl={refreshComponents.elevatorList}
              />
            </div>
            {/* /.card-body */}

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
    </>
  );
}

export default ElevatorsList
