import { React } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';



const Test = () => {

  axios.defaults.withCredentials = true;


  const createSession = () => {
    
    axios.post('https://api.gestelev.xyz/teste.php', {
      method: "POST"
      })
      .then(res => {
        const data = res.data;
        Cookies.set('PHPSESSID', data, { expires: 1, httpOnly: false });
        console.log(data);
      })
  };

  const requestSession = () => {
    
    axios.get('https://api.gestelev.xyz/teste2.php')
      .then(res => {
        const data = res.data;
        console.log(data);
      })

  };


  return (
    <>
    <button onClick={() => { createSession(); }}>
      Criar session
    </button>
    <button onClick={() => { requestSession(); }}>
      Testar session
    </button>
    </>
    
  );

};

export default Test;