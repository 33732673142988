import { React, useState, useEffect} from 'react';
import MuiDataGrid from '../DataGrid';
import { fetchAPI } from '../../functions/fetchAPI';
import SnackbarAlert from '../SnackbarAlert';
import getLocalData from "../../functions/getLocalData";
import ContractStatusTag from './contractStatusTag';
import ContractDetail from "./popup_details";

const ContractList = (props) => {
  const [data, setData] = useState([]);

  /* Alert */
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    alert: "",
  });
  /**/

  /* Loading */
  const [loading, setLoading] = useState(false);
  /**/

  const [modalDetail, setModalDetail] = useState({
    show: false,
    id: 0,
  });

  async function getContractList(customerId) {

    const CustomerId = customerId?customerId:0;

    setLoading(true);
    try {
      const fetchData = await fetchAPI(
        "/api.php/contract/list?customerId="+CustomerId+"&tenantId="+getLocalData("user", "tenant"),
        "GET"
      ); 
      const res = await fetchData;
      setData(res);
      setLoading(false);
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
        type: "error",
      });
    }
  }

  useEffect(() => {
    getContractList(props.customerId);
  }, [props.customerId,props.refreshControl]);

  const columns = [
    {
      field: "Reference",
      headerName: "Referência",
      flex: 1,
    },
    {
      field: "Customers_Name",
      headerName: "Cliente",
      flex: 1,
      renderCell: (params) => (
        params.row.Customers_Reference + ' - ' + params.row.Customers_Name
      ),
    },
    {
      field: "CustomersAddress_Street",
      headerName: "Endereço",
      flex: 1,
    },
    {
      field: "ProposalReference",
      headerName: "Proposta",
      flex: 1,
    },
    {
      field: "ContractsTypes_Name",
      headerName: "Tipo de Contrato",
      flex: 1,
    },
    { 
      field: "Proposals_Value", 
      headerName: "Valor", 
      flex: 1,
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return value.toLocaleString("pt-PT", {style:"currency", currency:"EUR"});
      },
    },
    { 
      field: "CustomersContracts_EffectiveValue", 
      headerName: "Valor Atual", 
      flex: 1,
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return value.toLocaleString("pt-PT", {style:"currency", currency:"EUR"});
      },
    },
    {
      field: "ContractStatus_Code",
      headerAlign: "center",
      align: "center",
      headerName: "Estado",
      flex: 1,
      renderCell: (params) => (
        <ContractStatusTag 
          StatusCode={params.row.ContractStatus_Code}
          StatusName={params.row.ContractStatus_Name}
        />
      ),
    },
    {
      field: "Actions",
      headerName: "",
      type: "actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => setModalDetail({show: true, id: params.row.CustomersContracts_Id})}
          >
            <i className="fas fa-folder pr-2"></i>
            Consultar
          </button>
        </>
      ),
    },
  ];

  const rows = data;

  return (
    <>
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />
      <MuiDataGrid
        rows={rows}
        columns={columns}
        pageSize={props.pageSize ? props.pageSize : 10}
        loading={loading}
        rowId={(params) => params.CustomersContracts_Id}
        blankStateOnlyText={props.blankStateOnlyText}
        sortAsc={true}
        sortField="Reference"
        columnVisibility={{
          ...props.columnVisibility,
        }}
      />

      <ContractDetail
        id={modalDetail.id}
        customerId={props.customerId}
        show={modalDetail.show}
        onHide={() => setModalDetail({ show: false, id: 0 })}
        onSave={() => getContractList(props.customerId)}
      />
    </>
  );
}

export default ContractList
