import { React, useState, useEffect } from "react";
import { fetchAPI } from '../../functions/fetchAPI';
import SnackbarAlert from '../SnackbarAlert';
import PopUpLoading from '../PopUpLoading';
import { Modal, Form } from "react-bootstrap";


const NoteDetail = (props) => {

    const [loading, setLoading] = useState({
        isLoading: false,
        message: "",
    });

    const [alert, setAlert] = useState({
        open: false,
        message: "",
        alert: "",
      });

    const [form_Data, setFormData] = useState({
        CustomersNotes_Note: '',
    });

    async function createNote() {
        setLoading({
          isLoading: true
        });
        setAlert({ open: false, type: alert.type, message: alert.message });
        try {
          const fetchData = await fetchAPI(
            "/api.php/notes/customer/create",
            "POST",
            {
              CustomersNotes: {
                CustomerId: props.customerId,
                Note: form_Data.CustomersNotes_Note
              }
            }
          );
          const res = await fetchData;
            setAlert({
              open: true,
              message: res.message,
              type: res.status,
            });
            setLoading({
              isLoading: false
            });
            props.onSave();
        } catch (err) {
            setAlert({
              open: true,
              message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
              type: "error",
            });
            setLoading({
              isLoading: false
            });
        }
    }

    useEffect(() => {
      setFormData({
        CustomersNotes_Note: "",
      });
    }, [props]);

    const [validated, set_Validated] = useState(false);

    const submitFn = (event) => {
        
        const form = event.currentTarget;

        set_Validated(true);

        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          event.preventDefault();
          createNote();
        }
    };

    const handleOnChange = (event) => {
      
        const { name, value } = event.target;

          setFormData({
            ...form_Data,
            [name]: value,
          });

    };

    
    return (
      <>
        <SnackbarAlert
          open={alert.open}
          message={alert.message}
          type={alert.type}
        />

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={props.show}
          onHide={() => {
            props.onHide();
          }}
          backdrop="static"
        >
          <PopUpLoading open={loading.isLoading} />
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Adicionar Nota
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate method="post" onSubmit={submitFn} id="noteForm">
              <div className="container-fluid pb-4">
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="Note" className="required">
                      Nota
                    </label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      name="CustomersNotes_Note"
                      className="form-control"
                      id="Note"
                      value={form_Data.CustomersNotes_Note}
                      required={true}
                      onChange={handleOnChange}
                      isInvalid={!form_Data.CustomersNotes_Note && validated}
                    />
                    <Form.Control.Feedback type="invalid">
                      A Nota é obrigatória!
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary mr-auto"
              type="submit"
              form="noteForm"
            >
              Adicionar
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
}


export default NoteDetail