import { React, useState, useEffect, useRef } from "react";
import { fetchAPI } from "../../functions/fetchAPI";
import { fetchAPIEnctype } from "../../functions/fetchAPIEnctype";
import SnackbarAlert from "../SnackbarAlert";
import PopUpLoading from "../PopUpLoading";
import { Modal, Form } from "react-bootstrap";
import MuiDataGrid from "../DataGrid";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AvatarUser from "../common/avatarUser";
import moment from "moment";
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';

const NoteDetail = (props) => {
  const [loading, setLoading] = useState({
    isLoading: false,
    message: "",
  });

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    alert: "",
  });

  const [modalShow, setModalShow] = useState(false);

  const [form_Data, setFormData] = useState({
    Customers_Id: "",
    CustomersNotes_Id: "",
    CustomersNotes_Note: "",
    CustomersNotes_CreatedAt: "",
    CustomersNotes_UpdatedAt: "",
    Users_CreatedBy_FirstName: "",
    Users_CreatedBy_LastName: "",
    UsersAvatar_CreatedBy_Base64: "",
    UsersAvatar_CreatedBy_MimeType: "",
    Users_UpdatedBy_FirstName: "",
    Users_UpdatedBy_LastName: "",
    UsersAvatar_UpdatedBy_Base64: "",
    UsersAvatar_UpdatedBy_MimeType: "",
    Files: [
      {
        CustomersNotesFiles_Id: "",
        CustomersNotesFiles_FileName: "",
        CustomersNotesFiles_FileSize: "",
        CustomersNotesFiles_FileMimeType: "",
        CustomersNotesFiles_CreatedAt: "",
        CustomersNotesFiles_UpdatedAt: "",
        Users_CreatedBy_FirstName: "",
        Users_CreatedBy_LastName: "",
        UsersAvatar_CreatedBy_Base64: "",
        UsersAvatar_CreatedBy_MimeType: "",
        Users_UpdatedBy_FirstName: "",
        Users_UpdatedBy_LastName: "",
        UsersAvatar_UpdatedBy_Base64: "",
        UsersAvatar_UpdatedBy_MimeType: "",
      },
    ],
  });

  const [selectedFile, setSelectedFile] = useState(null);

  const [validationError, setValidationError] = useState(null);

  const fileInputRef = useRef(null);

  async function getNoteDetail(id) {
    setLoading({
      isLoading: true,
    });
    try {
      const fetchData = await fetchAPI(
        "/api.php/notes/customer/detail?id=" + id,
        "GET"
      );
      const res = await fetchData;
      setFormData(res);
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
        type: "error",
      });
    }

    setLoading({
      isLoading: false,
    });
  }

  async function updateNote() {
    setLoading({
      isLoading: true,
      message: "A atualizar a nota ...",
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchData = await fetchAPI(
        "/api.php/notes/customer/update",
        "POST",
        {
          CustomersNotes: {
            Id: form_Data.CustomersNotes_Id,
            Note: form_Data.CustomersNotes_Note,
            CustomerId: form_Data.Customers_Id
          },
        }
      );
      const res = await fetchData;
      setAlert({
        open: true,
        message: res.message,
        type: res.status,
      });
      if (res.status !== "error") {
        getNoteDetail(res.Id);
        props.onSave();
      } else {
        setLoading({
          isLoading: false,
        });
      }
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
        type: "error",
      });
      setLoading({
        isLoading: false,
      });
    }
    set_Validated(false);
  }

  async function deleteFile(id) {
    setLoading({
      isLoading: true,
      message: "A eliminar o ficheiro ...",
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchData = await fetchAPI(
        "/api.php/notes/customer/deleteFile",
        "POST",
        {
          CustomersNotesFile: {
            Id: id,
            IsActive: 0,
          },
          Customers_Id: form_Data.Customers_Id,
        }
      );
      const res = await fetchData;
      setAlert({
        open: true,
        message: res.message,
        type: res.status,
      });
      if (res.status !== "error") {
        props.onSave();
      } else {
        setLoading({
          isLoading: false,
        });
      }
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
        type: "error",
      });
      setLoading({
        isLoading: false,
      });
    }

    
  }

  async function downloadFile(id) {
    setLoading({
      isLoading: true,
      message: "A obter documento ...",
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetch = await fetchAPI(
        "/api.php/notes/customer/download",
        "POST",
        { 
          CustomersNotesFiles_Id: id,
          Customer_Id: form_Data.Customers_Id
        }
      );      
      const res = await fetch;
      const a = document.createElement("a");
      a.href = `data:${res.FileMimeType};base64,${res.Base64}`;
      a.download = res.FileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      setLoading({
        isLoading: false,
        });
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
        type: "error",
      });
      setLoading({
        isLoading: false,
      });
    }
  }

  async function uploadFile() {
    if (selectedFile) {
      setLoading({
        isLoading: true,
        message: "A carregar ficheiro ...",
      });
      setAlert({ open: false, type: alert.type, message: alert.message });
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("CustomersNotes", JSON.stringify({
            Id: form_Data.CustomersNotes_Id,
            Customers_Id: form_Data.Customers_Id
        }));
        const fetchData = await fetchAPIEnctype(
          "/api.php/notes/customer/upload",
          formData
        );
        const res = await fetchData;
        setAlert({
          open: true,
          message: res.message,
          type: res.status,
        });
        if (res.status !== "error") {
          props.onSave();
        } else {
          setLoading({
            isLoading: false,
          });
        }
      } catch (err) {
        setAlert({
          open: true,
          message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
          type: "error",
        });
        setLoading({
          isLoading: false,
        });
      }

      fileInputRef.current.value = "";
    } else {
      setSelectedFile(null);
      setValidationError("Escolha um ficheiro para fazer upload!");
    }
  }

  useEffect(() => {
    if (props.show) {
      setModalShow(true);
      getNoteDetail(props.id);
    }
  }, [props]);

  const [validated, set_Validated] = useState(false);

  const submitFn = (event) => {
    const form = event.currentTarget;

    set_Validated(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      updateNote();
    }
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...form_Data,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setValidationError(null);
    } else {
      setSelectedFile(null);
      setValidationError("Escolha um ficheiro para fazer upload!");
    }
  };

  const columns = [
    {
      field: "CustomersNotesFiles_FileName",
      headerName: "Ficheiro",
      flex: 1,
    },
    {
      field: "CustomersNotesFiles_FileMimeType",
      headerName: "Tipo",
      flex: 1,
    },
    {
      field: "CustomersNotesFiles_UpdatedAt",
      headerName: "Data",
      flex: 1,
      renderCell: (params) =>
        moment(params.row.DateTime).format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "Actions",
      headerName: "",
      type: "actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <button
            className="btn btn-primary btn-sm"
            data-tooltip-id="tooltip"
            data-tooltip-content="Descarregar Ficheiro"
            data-tooltip-place="top"
            onClick={() => downloadFile(params.row.CustomersNotesFiles_Id)}
          >
            <i className="fas fa-download"></i>
          </button>
          <button
            className="btn btn-danger btn-sm ml-2"
            data-tooltip-id="tooltip"
            data-tooltip-content="Eliminar Ficheiro"
            data-tooltip-place="top"
            onClick={() => 
              Swal.fire({
                title: "Eliminar ficheiro",
                text: "O ficheiro "+params.row.CustomersNotesFiles_FileName+" irá ser permanentemente eliminado. Pretende continuar?",
                icon: "error",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonText: "Cancelar",
                confirmButtonText: "Sim, continuar"
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteFile(params.row.CustomersNotesFiles_Id);
                }
              })
            }
          >
            <i className="fas fa-trash"></i>
          </button>
        </>
      ),
    },
  ];

  const rows = form_Data.Files;

  return (
    <>
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => {
          props.onHide();
          setModalShow(false);
        }}
        backdrop="static"
      >
        <PopUpLoading open={loading.isLoading} />
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalhes da Nota
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid pb-4">
            <div className="card card-secondary card-outline card-outline-tabs">
              <div className="card-header p-0 border-bottom-0">
                <ul
                  className="nav nav-tabs"
                  id="custom-tabs-four-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="custom-tabs-four-home-tab"
                      data-toggle="pill"
                      href="#noteTab_1"
                      role="tab"
                      aria-controls="noteTab_1"
                      aria-selected="true"
                    >
                      Informação da Nota
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-four-home-tab"
                      data-toggle="pill"
                      href="#noteTab_2"
                      role="tab"
                      aria-controls="noteTab_2"
                      aria-selected="true"
                    >
                      Anexos
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content">
                  <div className="tab-pane active" id="noteTab_1">
                    <Form
                      noValidate
                      method="post"
                      onSubmit={submitFn}
                      id="noteForm"
                    >
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="Note" className="required">
                            Nota
                          </label>
                          <Form.Control
                            as="textarea"
                            rows={6}
                            name="CustomersNotes_Note"
                            className="form-control"
                            id="Note"
                            value={form_Data.CustomersNotes_Note}
                            required={true}
                            onChange={handleOnChange}
                            isInvalid={
                              !form_Data.CustomersNotes_Note && validated
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            A Nota é obrigatória!
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </Form>
                    <div className="row mt-4">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ArrowDownwardIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <label>Meta Dados</label>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="row">
                            <div className="col-6">
                              <label>Criado por</label>
                              <div className="col-12">
                                <AvatarUser
                                  Name={
                                    form_Data.Users_CreatedBy_FirstName +
                                    " " +
                                    form_Data.Users_CreatedBy_LastName
                                  }
                                  Base64={
                                    form_Data.UsersAvatar_CreatedBy_Base64
                                  }
                                  MimeType={
                                    form_Data.UsersAvatar_CreatedBy_MimeType
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <label>Atualizado por</label>
                              <div className="col-12">
                                <AvatarUser
                                  Name={
                                    form_Data.Users_UpdatedBy_FirstName +
                                    " " +
                                    form_Data.Users_UpdatedBy_LastName
                                  }
                                  Base64={
                                    form_Data.UsersAvatar_UpdatedBy_Base64
                                  }
                                  MimeType={
                                    form_Data.UsersAvatar_UpdatedBy_MimeType
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-6">
                              <label>Criado em</label>
                              <div className="col-12">
                                {moment(
                                  form_Data.CustomersNotes_CreatedAt
                                ).format("DD/MM/YYYY HH:mm")}
                              </div>
                            </div>
                            <div className="col-6">
                              <label>Atualizado em</label>
                              <div className="col-12">
                                {moment(
                                  form_Data.CustomersNotes_UpdatedAt
                                ).format("DD/MM/YYYY HH:mm")}
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                  <div className="tab-pane" id="noteTab_2">
                    <div className="row">
                      <label htmlFor="Files" className="required">
                        Carregar Anexo
                      </label>
                      <div className="col-10">
                        <Form.Control
                          id="Files"
                          name="file"
                          type="file"
                          onChange={handleFileChange}
                          ref={fileInputRef}
                        />
                        {validationError && (
                          <div
                            style={{ fontSize: "80%" }}
                            className="text-danger"
                          >
                            {validationError}
                          </div>
                        )}
                      </div>
                      <div className="col-2">
                        <button
                          className="btn btn-primary w-100"
                          onClick={uploadFile}
                        >
                          <i className="fas fa-upload pr-2"></i>
                          Upload
                        </button>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <MuiDataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        sortAsc={false}
                        sortField="CustomersNotesFiles_UpdatedAt"
                        rowId={(params) => params.CustomersNotesFiles_Id}
                        blankStateOnlyText={true}
                      />
                      <Tooltip id="tooltip" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary mr-auto"
            type="submit"
            form="noteForm"
          >
            Gravar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NoteDetail;
