import { React, useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import PopUpLoading from "../PopUpLoading";
import { fetchAPI } from "../../functions/fetchAPI";
import SnackbarAlert from "../SnackbarAlert";
import getLocalData from "../../functions/getLocalData";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import validateContact from '../../functions/validateCustomerContact';

const SendEmail = (props) => {

    const [loading, setLoading] = useState({
        isLoading: false,
        message: "",
    });

    const [alert, setAlert] = useState({
      open: false,
      message: "",
      alert: "",
    });

    const [modalShow, setModalShow] = useState(false);

    const [form_Data, setFormData] = useState({
      FromEmail: "",
      EmailTemplatesCustom_Subject: "",
      EmailTemplatesCustom_Text: "",
      SelectedCustomersContacts_Id: "",
      SendCopy: false,
      SendCopyTo: "",
      CustomersContacts: [
        {
          CustomersContacts_Id: "",
          CustomersContacts_Value: "",
        },
      ],
    });

    const [ckEditorBody, setCkEditorBody] = useState("");

    const [validated, set_Validated] = useState(false);

    const [contactEMAIL, setContactEMAIL] = useState({
        isInvalid: false,
        message: ''
    });

    async function getEmailToCustomer(props) {
      setLoading({
        isLoading: true,
        message: "A obter dados ...",
      });
      setAlert({ open: false, type: alert.type, message: alert.message });
      try {
        const fetch = await fetchAPI(
          `/api.php/email/get?customerId=${props.customerId}&emailTemplateId=${props.emailTemplateId}&tenantId=${getLocalData("tenant", "id")}&isOnlyBody=1`,
          "GET"
        );
        const res = await fetch;
        setFormData(res);
        setCkEditorBody(res.EmailTemplatesCustom_Text);
      } catch (err) {
        setAlert({
          open: true,
          message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
          type: "error",
        });
      }
  
      setLoading({
        isLoading: false,
        message: "",
      });
    }

    async function sendEmail(props) {
      setLoading({
        isLoading: true,
        message: "A obter dados ...",
      });
      setAlert({ open: false, type: alert.type, message: alert.message });
      try {
        const fetch = await fetchAPI(
          "/api.php/email/send",
          "POST",
          { 
            CustomersContacts_Id: form_Data.SelectedCustomersContacts_Id,
            Subject: form_Data.EmailTemplatesCustom_Subject,
            Body: ckEditorBody,
            SendCopy: form_Data.SendCopy,
            SendCopyTo: form_Data.SendCopyTo,
            EmailTemplateId: props.emailTemplateId,
            ReferenceId: props.referenceId
          }
        );
        const res = await fetch;
        setAlert({
          open: true,
          message: res.message,
          type: res.status,
        });
        if(res.status!=='error') {
          props.onSave();
          setModalShow(false);
        } else {
          setLoading({
            isLoading: false
          });
        }
      } catch (err) {
        setAlert({
          open: true,
          message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
          type: "error",
        });
      }
  
      setLoading({
        isLoading: false,
        message: "",
      });
    }

    const submitFn = (event) => {
      const form = event.currentTarget;
  
      set_Validated(true);
  
      if (!form.checkValidity() || 
        (form_Data.SendCopy && !form_Data.SendCopyTo) ||
        contactEMAIL.isInvalid) {

        event.preventDefault();
        event.stopPropagation();
      } else {
        event.preventDefault();
        sendEmail(props);
      }
    };

    const handleOnChange = (event) => {
      const { id, value } = event.target;

      if(id==='SendCopyTo') {
        setContactEMAIL(validateContact(value,'EMAIL'));
      }

      setFormData({
        ...form_Data,
        [id]: value,
      });
    };

    const handleSwitchOnChange = (event) => {
      const { id, checked } = event.target;
      setFormData({
        ...form_Data,
        [id]: checked,
      });
    };


    useEffect(() => {
        if (props.show) {
          setModalShow(true);
          getEmailToCustomer(props);
        }
    }, [props]);

  return (
    <>
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => {
          props.onHide();
          setModalShow(false);
        }}
        backdrop="static"
      >
        <PopUpLoading open={loading.isLoading} />
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Enviar email
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate method="post" onSubmit={submitFn} id="sendEmailForm">
            <div className="container-fluid pb-5">
              <div className="row">
                <div className="col-12">
                  <label>Remetente</label>
                  <div>{form_Data.FromEmail}</div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <label htmlFor="CustomersContactsSelect" className="required">
                    Endereço de Envio
                  </label>
                  <Form.Select
                    type="select"
                    as="select"
                    name="CustomersContactsSelect"
                    onChange={handleOnChange}
                    id="SelectedCustomersContacts_Id"
                    required={true}
                    isInvalid={
                      !form_Data.SelectedCustomersContacts_Id && validated
                    }
                  >
                    <option value="">Escolher contacto</option>
                    {form_Data.CustomersContacts.map((item, index) => (
                      <option key={index} value={item.CustomersContacts_Id}>
                        {item.CustomersContacts_Value}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    O Endereço de Envio é obrigatório!
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 mb-4">
                  <label htmlFor="SendCopySwitch">Enviar Cópia</label>
                  <Form.Check
                    type="switch"
                    name="SendCopySwitch"
                    autocomplete="off"
                    id="SendCopy"
                    onChange={handleSwitchOnChange}
                  />
                </div>
              </div>
              {form_Data.SendCopy ? (
                <div className="row mt-4">
                  <div className="col-12">
                    <label htmlFor="SendCopyToInput" className="required">
                      Cópia de Envio para
                    </label>
                    <Form.Control
                      type="text"
                      name="SendCopyToInput"
                      autoComplete="off"
                      className="form-control"
                      id="SendCopyTo"
                      value={form_Data.SendCopyTo}
                      isInvalid={
                          (!form_Data.SendCopyTo || contactEMAIL.isInvalid) && 
                          validated
                        }
                      onChange={handleOnChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {!form_Data.SendCopyTo?'O Endereço para envio da cópia é obrigatório!':contactEMAIL.message}
                    </Form.Control.Feedback>
                  </div>
                </div>
              ) : null}
              <div className="row mt-4">
                <div className="col-12">
                  <label htmlFor="SubjectInput" className="required">
                    Assunto
                  </label>
                  <Form.Control
                    type="text"
                    name="SubjectInput"
                    autoComplete="off"
                    className="form-control"
                    id="EmailTemplatesCustom_Subject"
                    value={form_Data.EmailTemplatesCustom_Subject}
                    isInvalid={
                      form_Data.EmailTemplatesCustom_Subject === "" && validated
                    }
                    onChange={handleOnChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    O Assunto é obrigatório!
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <CKEditor
                    editor={ ClassicEditor }
                    data={ ckEditorBody }
                    onChange={( event, editor ) => {
                      const data = editor.getData();
                      setCkEditorBody(data);
                    }}
                    config={ {
                        toolbar: {
                            items: [ 'undo', 'redo', '|', 'bold', 'italic' ],
                        },
                        plugins: [
                            Bold, Essentials, Italic, Mention, Paragraph, Undo
                        ]
                    } }
                  />
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary mr-auto"
            type="submit"
            form="sendEmailForm"
          >
            Enviar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SendEmail;
