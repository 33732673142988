import { React, useState, useEffect} from 'react';
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import SideNav from "../../../components/SideNav";
import Footer from "../../../components/Footer";
import DocumentTitle from '../../../functions/setDocumentTitle';
import MuiDataGrid from '../../../components/DataGrid';
import { fetchAPI } from '../../../functions/fetchAPI';
import getLocalData from "../../../functions/getLocalData";
import SnackbarAlert from '../../../components/SnackbarAlert';
import Chip from '@mui/material/Chip';

const CustomersList = () => {
  const [data, setData] = useState([]);

  /* Alert */
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    alert: "",
  });
  /**/

  /* Loading */
  const [loading, setLoading] = useState(false);
  /**/

  async function getCustomersList() {
    setLoading(true);
    try {
      const fetchData = await fetchAPI(
        "/api.php/customer/list?tenant=" + getLocalData("user", "tenant"),
        "GET"
      );
      const res = await fetchData;
      setData(res);
      setLoading(false);
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
        type: "error",
      });
    }
  }

  useEffect(() => {
    getCustomersList();
  }, []);

  const navigate = useNavigate();

  const columns = [
    { field: "Customer_Reference", headerName: "Referência", width: 130 },
    { field: "Customer_Name", headerName: "Nome", flex: 1 },
    { field: "Customer_Street", headerName: "Endereço", flex: 1 },
    { field: "Customer_TaxNumber", headerName: "Nº Contribuinte", width: 160 },
    { field: "Route_Name", headerName: "Rota", width: 130 },
    { field: "CustomerManager_Name", headerName: "Gestora", flex: 1},
    {
      field: "CustomerStatus_Name",
      headerAlign: "center",
      align: "center",
      headerName: "Estado",
      width: 130,
      renderCell: (params) => (
        params.row.CustomerStatus_Code === "ACTIVE"
          ? <Chip label={params.row.CustomerStatus_Name} color="primary" />
          : <Chip label={params.row.CustomerStatus_Name} color="default" />
      ),
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => navigate("/customer/details", { state: { id: params.row.Customer_Id } })}
          >
            <i className="fas fa-folder pr-2"></i>
            Consultar
          </button>
        </>
      ),
    },
  ];

  const rows = data;

  return (
    <>
      {DocumentTitle("Clientes")}
      <Header />
      <SideNav activeItem={2} />
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Clientes</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard/main">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Clientes</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="mb-3 text-right">
            <button className="btn btn-primary btn-l" onClick={() => navigate("/customer/create")} >
              <i className="fas fa-plus pr-2"></i>
                Criar Cliente
            </button>
          </div>
          {/* Default box */}
          <div className="card">
            <div className="card-body p-0">
              <MuiDataGrid
                rows={rows}
                columns={columns}
                pageSize={50}
                sortAsc={true}
                sortField="Customer_Name"
                loading={loading}
                rowId={(params) => params.Customer_Id}
              />
            </div>
            {/* /.card-body */}

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      <Footer />
    </>
  );
}

export default CustomersList
