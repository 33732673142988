import {
  HashRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import AuthProvider from "./hooks/AuthProvider";
import PrivateRoute from "./router/private";

/* Import Private */
import Dashboard from "./pages/private/dashboard/main"
import CustomerList from "./pages/private/customer/list"
import CustomerDetails from "./pages/private/customer/details"
import CustomerCreate from "./pages/private/customer/create"
import ProposalList from "./pages/private/proposal/list"
import ContractList from "./pages/private/contract/list"
import BudgetProposalList from "./pages/private/budgetproposal/list"
import ElevatorList from "./pages/private/elevator/list"

/* Import Public */
import Login from "./pages/public/login"
import Test from "./pages/public/test"

function App() {

  return (
    <>
      <Router>
        <AuthProvider>
            <Routes>
              <Route path="/login" element={<Login />} />ﬂ
              <Route path="/test" element={<Test />} />
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard/main" element={<Dashboard />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/customer/list" element={<CustomerList />} />
                <Route path="/customer/details" element={<CustomerDetails />} />
                <Route path="/customer/create" element={<CustomerCreate />} />
                <Route path="/proposal/list" element={<ProposalList />} />
                <Route path="/contract/list" element={<ContractList />} />
                <Route path="/budgetproposal/list" element={<BudgetProposalList />} />
                <Route path="/elevator/list" element={<ElevatorList />} />
              </Route>
            </Routes>        
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
