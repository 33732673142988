import { React, useState, useEffect } from "react";
import { fetchAPI } from "../../functions/fetchAPI";
import SnackbarAlert from "../SnackbarAlert";
import PopUpLoading from "../PopUpLoading";
import { Modal, Form } from "react-bootstrap";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AvatarUser from "../common/avatarUser";
import moment from "moment";
import getLocalData from "../../functions/getLocalData";
import MuiDataGrid from '../DataGrid';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import SendEmail from "../email/send_CustomerEmail";

const ContractDetail = (props) => {

  const [loading, setLoading] = useState({
    isLoading: false,
    message: "",
  });

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    alert: "",
  });

  const [modalShow, setModalShow] = useState(false);
  const [modalEmailShow, setModalEmailShow] = useState(false);

  const [form_Data, setFormData] = useState({
    CustomersContracts_Reference: "",
    CustomersContracts_SentDate: "",
    CustomersContracts_StartDate: "",
    CustomersContracts_EffectiveValue: "",
    CustomersContracts_EndDate: "",
    CustomersContracts_LastRenovationDate: "",
    CustomersContracts_ContractStatusId: "",
    CustomersContracts_PaymentsFrequencyId: "",
    CustomersContracts_NextRenovationDate: "",
    CustomersContracts_CreatedAt: "",
    CustomersContracts_UpdatedAt: "",
    ContractStatus_Code: "",
    Customer_Id: "",
    Customer_Reference: "",
    Customer_Name: "",
    Proposals_Id: "",
    Proposals_ContractDurationYears: 0,
    Proposals_Value: "",
    Proposals_SpecialConditions: "",
    Users_CreatedBy_FirstName: "",
    Users_CreatedBy_LastName: "",
    UsersAvatar_CreatedBy_Base64: "",
    UsersAvatar_CreatedBy_MimeType: "",
    Users_UpdatedBy_FirstName: "",
    Users_UpdatedBy_LastName: "",
    UsersAvatar_UpdatedBy_Base64: "",
    UsersAvatar_UpdatedBy_MimeType: "",
    Proposals: [
      {
        Proposals_Id: "",
        ContractsTypes_Name: "",
      }
    ],
    Elevators: [
      {
        ProposalElevatorRelation_Id: "",
        Elevator_Id: "",
        Elevator_Number: "",
        Elevator_Floors: "",
        Elevator_Load: "",
        Elevator_Speed: "",
        Manufactuer_Name: "",
        InstallationType_Name: "",
      },
    ],
    UnrelatedElevators: [
      {
        Elevator_Id: "",
        Elevator_Number: "",
      },
    ]
  });

  const [proposalList, setProposal] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  const [elevatorsRelationList, setElevatorsRelation] = useState([
    {
      Elevator_Id: "",
      Elevator_Number: "",
    },
  ]);

  const [selectedElevator, setSelectedElevator] = useState({
    Elevator_Id: ""
  });

  const [statusList, setStatus] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  const [paymentFrequencyList, setPaymentFrequency] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  async function getContractDetail(id) {
    setLoading({
      isLoading: true,
      message: "A obter dados ...",
    });
    try {
      const fetchContractDetail = await fetchAPI(
        "/api.php/contract/detail?id=" + id,
        "GET"
      );
      const resContractDetail = await fetchContractDetail;

      const fetchProposals = await fetchAPI(
        "/api.php/proposal/list?tenantId=" +
          getLocalData("tenant", "id") +
          "&customerId=" + resContractDetail.Customer_Id,
        "GET"
      );
      const resProposals = await fetchProposals;

      const fetchStatus = await fetchAPI(
        "/api.php/contract/listStatus", 
        "GET"
      );
      const resStatus = await fetchStatus;

      const fetchPaymentFrequency = await fetchAPI(
        "/api.php/contract/listPaymentFrequency", 
        "GET"
      );
      const resPaymentFrequency = await fetchPaymentFrequency;

      setFormData(resContractDetail);
      setProposal(resProposals);
      setStatus(resStatus);
      setPaymentFrequency(resPaymentFrequency);
      setElevatorsRelation(resContractDetail.UnrelatedElevators);
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
        type: "error",
      });
    }

    setLoading({
      isLoading: false,
      message: "",
    });
  }

  async function getProposalDetail(id) {
    setLoading({
      isLoading: true,
      message: "A obter dados ...",
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchProposalDetail = await fetchAPI(
        "/api.php/proposal/detail?id=" + id,
        "GET"
      );
      const resProposalDetail = await fetchProposalDetail;
      setFormData({
        ...form_Data,
        Proposals_Id: resProposalDetail.Proposal_Id,
        Proposals_ContractDurationYears: resProposalDetail.Proposal_ContractDurationYears,
        Proposals_Value: resProposalDetail.Proposal_Value,
        Elevators: resProposalDetail.Elevators,
        UnrelatedElevators: resProposalDetail.UnrelatedElevators
      });
      setElevatorsRelation(resProposalDetail.UnrelatedElevators);
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
        type: "error",
      });
    }

    setLoading({
      isLoading: false,
      message: "",
    });
  }

  async function addElevator() {
    setLoading({
      isLoading: true,
      message: "A criar relação ...",
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchData = await fetchAPI("/api.php/proposal/addElevatorRelation", "POST", {
        Proposal_Id: form_Data.Proposals_Id,
        Elevator_Id: selectedElevator.Elevator_Id,
        Customer_Id: form_Data.Customer_Id,
      });
      const res = await fetchData;
      setAlert({
        open: true,
        message: res.message,
        type: res.status,
      });
      if (res.status !== "error") {
        getContractDetail(props.id);
      } else {
        setLoading({
          isLoading: false,
        });
      }
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
        type: "error",
      });
      setLoading({
        isLoading: false,
      });
    }
    setFormElevatorValid(false);
  }

  async function deleteElevator(id) {
    setLoading({
      isLoading: true,
      message: "A eliminar relação ...",
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchData = await fetchAPI("/api.php/proposal/deleteElevatorRelation","POST", {
        Proposal_Id: form_Data.Proposals_Id,
        Elevator_Id: id,
        Customer_Id: form_Data.Customer_Id,
      });
      const res = await fetchData;
      setAlert({
        open: true,
        message: res.message,
        type: res.status,
      });
      if (res.status !== "error") {
        getContractDetail(props.id);
      } else {
        setLoading({
          isLoading: false,
        });
      }
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
        type: "error",
      });
      setLoading({
        isLoading: false,
      });
    }
  }

  async function updateContract() {
    setLoading({
      isLoading: true,
      message: "A atualizar o contrato ..."
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchData = await fetchAPI(
        "/api.php/contract/update",
        "POST",
        {
          Customers_Contracts: {
            Id: props.id,
            PaymentFrequencyId: form_Data.CustomersContracts_PaymentsFrequencyId,
            StartDate: form_Data.StartDate,
            EndDate: form_Data.EndDate,
            ProposalId: form_Data.Proposals_Id,
            ContractStatusId: form_Data.CustomersContracts_ContractStatusId,
            SentDate: form_Data.CustomersContracts_SentDate,
            EffectiveValue: form_Data.CustomersContracts_EffectiveValue,
          },
          Proposals: {
            Id: form_Data.Proposals_Id,
            SpecialConditions: form_Data.Proposals_SpecialConditions,
          },
          Customers_Id: form_Data.Customer_Id,
        }
      );
      const res = await fetchData;
        setAlert({
          open: true,
          message: res.message,
          type: res.status,
        });
        if(res.status!=='error') {
          getContractDetail(props.id);
          props.onSave();
        } else {
          setLoading({
            isLoading: false
          });
        }
    } catch (err) {
        setAlert({
          open: true,
          message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
          type: "error",
        });
        setLoading({
          isLoading: false
        });
    }
    set_Validated(false);
  }

  async function downloadContract(id) {
    setLoading({
      isLoading: true,
      message: "A obter documento ...",
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetch = await fetchAPI(
        "/api.php/pdf/contract",
        "POST",
        { ContractId: id }
      );      
      const res = await fetch;
      const a = document.createElement("a");
      a.href = `data:application/pdf;base64,${res.Base64}`;
      a.download = `${res.FileName}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      
    } catch (error) {
      console.log(error.stack);
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter o documento. Tente novamente.",
        type: "error",
      });
    }

    setLoading({
      isLoading: false,
      message: "",
    });
  }

  useEffect(() => {
    if (props.show) {
      setModalShow(true);
      getContractDetail(props.id);
    }
  }, [props]);

  const [validated, set_Validated] = useState(false);

  const submitFn = (event) => {
    const form = event.currentTarget;

    set_Validated(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      updateContract();
    }
  };

  const [formElevatorValid, setFormElevatorValid] = useState(false);

  const submitElevatorFn = (event) => {
    const form = event.currentTarget;

    setFormElevatorValid(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      addElevator();
    }
  };

  const handleOnChange = (event) => {
    const { id, value, selectedIndex } = event.target;

    if (id === "CustomersContracts_ContractStatusId" && value) {
      setFormData({
        ...form_Data,
        [id]: value,
        ContractStatus_Code: statusList[selectedIndex - 1].Code,
      });
    } else {
      setFormData({
        ...form_Data,
        [id]: value,
      });
    }
  };

  const handleProposalOnChange = (event) => {
    const { value } = event.target;

    if(value) {
      getProposalDetail(value);
    }

    handleOnChange(event);
  };

  const handleElevatorOnChange = (event) => {
    const { id, value } = event.target;
    setSelectedElevator({
      [id]: value,
    });
  };

  const columns = [
    {
      field: "Elevator_Number",
      headerName: "Elevador Nº",
      flex: 1,
    },
    {
      field: "Manufactuer_Name",
      headerName: "Fabricante",
      flex: 1,
    },
    {
      field: "InstallationType_Name",
      headerName: "Tipo de Instalação",
      flex: 1,
    },
    {
      field: "Elevator_Floors",
      headerName: "Pisos",
      flex: 1,
    },
    {
      field: "Elevator_Load",
      headerName: "Carga",
      flex: 1,
    },
    {
      field: "Elevator_Speed",
      headerName: "Velocidade m/s",
      flex: 1,
    },
    {
      field: "Actions",
      headerName: "",
      type: "actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <button
            className="btn btn-danger btn-sm ml-2"
            data-tooltip-id="tooltip"
            data-tooltip-content="Eliminar Relação"
            data-tooltip-place="top"
            onClick={() => 
              Swal.fire({
                title: "Eliminar relação",
                text: "A relação do elevador "+params.row.Elevator_Number+" com a proposta "+form_Data.Customer_Reference+" será eliminada. Pretende continuar?",
                icon: "error",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonText: "Cancelar",
                confirmButtonText: "Sim, continuar"
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteElevator(params.row.Elevator_Id);
                }
              })
            }
          >
            <i className="fas fa-trash"></i>
          </button>
        </>
      ),
    },
  ];

  const rows = form_Data.Elevators;

  const SentDate = () => (
    <>
      <label htmlFor="SentDate" className="required">
        Data de Envio
      </label>
      <Form.Control
        type="date"
        name="SentDate"
        className="form-control"
        id="CustomersContracts_SentDate"
        value={form_Data.CustomersContracts_SentDate}
        onChange={handleOnChange}
        required={true}
        isInvalid={!form_Data.CustomersContracts_SentDate && validated}
      />
      <Form.Control.Feedback type="invalid">
        A Data de Envio é obrigatória!
      </Form.Control.Feedback>
    </>
  );


  const EndDate = () => (
    <>
      <label htmlFor="ContractEndDate" className="required">
        Data Final
      </label>
      <Form.Control
        type="date"
        name="ContractEndDate"
        className="form-control"
        id="CustomersContracts_EndDate"
        value={form_Data.CustomersContracts_EndDate}
        onChange={handleOnChange}
        required={true}
        isInvalid={!form_Data.CustomersContracts_EndDate && validated}
      />
      <Form.Control.Feedback type="invalid">
        A Data Final é obrigatória!
      </Form.Control.Feedback>
    </>
  );

  const ElevatorRelation = () => (
    <>
      <Form
        noValidate
        method="post"
        onSubmit={submitElevatorFn}
      >
        <div className="row mb-5">
          <label htmlFor="ProposalElevatorRelation">
            Elevadores Disponíveis
          </label>
          <div className="col-9">
            <Form.Select
              type="select"
              as="select"
              name="ProposalElevatorRelation"
              onChange={handleElevatorOnChange}
              value={selectedElevator.Elevator_Id}
              id="Elevator_Id"
              required={true}
              isInvalid={
                !selectedElevator.Elevator_Id && formElevatorValid
              }
            >
              <option value="">Escolher Elevador</option>
              {elevatorsRelationList.length>1?
                <option value="all">Relacionar Todos</option>
              :null}
              {elevatorsRelationList.map((item, index) => (
                <option key={index} value={item.Elevator_Id}>
                  {"Elevador " + item.Elevator_Number}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              O Elevador é obrigatório!
            </Form.Control.Feedback>
          </div>
          <div className="col-3">
            <button className="btn btn-primary w-100" type="submit">
              <i className="fas fa-link pr-2"></i>
              Relacionar
            </button>
          </div>
        </div>
      </Form>
    </>
  );

  return (
    <>
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />
      <SendEmail
        show={modalEmailShow}
        referenceId={props.id}
        customerId={form_Data.Customer_Id}
        emailTemplateId="CONTSEND"
        onHide={() => {
          setModalEmailShow(false);
        }}
        onSave={() => {
          setModalEmailShow(false);
          getContractDetail(props.id);
          props.onSave();
        }}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => {
          props.onHide();
          setModalShow(false);
        }}
        backdrop="static"
      >
        <PopUpLoading open={loading.isLoading} />
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalhes do Contrato
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid pb-4">
            <div className="row">
              <div className="col-6">
                <h4>Referência {form_Data.Customer_Reference}</h4>
              </div>
              <div className="col-6 text-right">
                <button 
                  onClick={() => {
                    downloadContract(props.id);
                  }}
                  className="btn btn-primary btn-sm"
                  data-tooltip-id="tooltip"
                  data-tooltip-content="Descarregar Contrato"
                  data-tooltip-place="top"
                  >
                  <i className="fas fa-download"></i>
                </button>
                <button
                  className="btn btn-primary btn-sm ml-2"
                  data-tooltip-id="tooltip"
                  data-tooltip-content="Enviar email"
                  data-tooltip-place="top"
                  onClick={() => {
                    setModalEmailShow(true);
                  }}
                >
                  <i className="fas fa-envelope"></i>
                </button>
                <Tooltip id="tooltip" />
              </div>
            </div>
            <div className="card card-secondary card-outline card-outline-tabs mt-2">
              <div className="card-header p-0 border-bottom-0">
                <ul
                  className="nav nav-tabs"
                  id="custom-tabs-four-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="custom-tabs-four-home-tab"
                      data-toggle="pill"
                      href="#contractTab_1"
                      role="tab"
                      aria-controls="contractTab_1"
                      aria-selected="true"
                    >
                      Informação do Contrato
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-four-home-tab"
                      data-toggle="pill"
                      href="#contractTab_2"
                      role="tab"
                      aria-controls="contractTab_2"
                      aria-selected="true"
                    >
                      Elevadores
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content">
                  <div className="tab-pane active" id="contractTab_1">
                    <Form
                      noValidate
                      method="post"
                      onSubmit={submitFn}
                      id="proposalForm"
                    >
                      <div className="row">
                        <div className="col-6">
                          <label htmlFor="CustomersSelect">
                            Cliente
                          </label>
                          <div>
                          { form_Data.Customer_Reference + " - " + form_Data.Customer_Name }
                          </div>
                        </div>
                        <div className="col-6"></div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-4">
                          <label
                            htmlFor="ProposalSelect"
                            className="required"
                          >
                            Proposta
                          </label>
                          <Form.Select
                            type="select"
                            as="select"
                            name="ProposalSelect"
                            onChange={handleProposalOnChange}
                            value={form_Data.Proposals_Id}
                            id="Proposals_Id"
                            required={true}
                            isInvalid={
                              !form_Data.Proposals_Id && validated
                            }
                          >
                            <option value="">Escolher a Proposta</option>
                            {proposalList.map((item, index) => (
                              <option key={index} value={item.Proposal_Id}>
                                {item.Customers_Reference + ' - ' + item.ContractsTypes_Name}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            A Proposta é obrigatória!
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-4">
                          <label htmlFor="StatusId" className="required">
                            Estado
                          </label>
                          <Form.Select
                            type="select"
                            as="select"
                            name="StatusId"
                            onChange={handleOnChange}
                            value={form_Data.CustomersContracts_ContractStatusId}
                            id="CustomersContracts_ContractStatusId"
                            required={true}
                            isInvalid={
                              !form_Data.CustomersContracts_ContractStatusId && validated
                            }
                          >
                            <option value="">Escolher Estado</option>
                            {statusList.map((item, index) => (
                              <option key={index} value={item.Id}>
                                {item.Name}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            O Estado é obrigatório!
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-4">
                          {form_Data.ContractStatus_Code !== "SENTPENDING" ? (
                            <SentDate />
                          ) : null}
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-3">
                          <label htmlFor="PaymentFrequencySelect" className="required">
                            Frequência de Pagamento
                          </label>
                          <Form.Select
                            type="select"
                            as="select"
                            name="PaymentFrequencySelect"
                            onChange={handleOnChange}
                            value={form_Data.CustomersContracts_PaymentsFrequencyId}
                            id="CustomersContracts_PaymentsFrequencyId"
                            required={true}
                            isInvalid={
                              !form_Data.CustomersContracts_PaymentsFrequencyId && validated
                            }
                          >
                            <option value="">Escolher a Frequencia Pagamento</option>
                            {paymentFrequencyList.map((item, index) => (
                              <option key={index} value={item.Id}>
                                {item.Name}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            A Frequencia de Pagamento é obrigatória!
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-3">
                          <label htmlFor="ContractStartDate" className="required">
                            Data de Início
                          </label>
                          <Form.Control
                            type="date"
                            name="ContractStartDate"
                            className="form-control"
                            id="CustomersContracts_StartDate"
                            value={form_Data.CustomersContracts_StartDate}
                            onChange={handleOnChange}
                            required={true}
                            isInvalid={
                              !form_Data.CustomersContracts_StartDate && validated
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            A Data de Início é obrigatória!
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-3">
                          <label htmlFor="EffectiveValue" className="required">
                            Valor atual
                          </label>
                          <Form.Control
                            type="number"
                            name="EffectiveValue"
                            className="form-control"
                            id="CustomersContracts_EffectiveValue"
                            value={form_Data.CustomersContracts_EffectiveValue}
                            onChange={handleOnChange}
                            required={true}
                            isInvalid={!form_Data.CustomersContracts_EffectiveValue && validated}
                          />
                          <Form.Control.Feedback type="invalid">
                            O Valor atual é obrigatório!
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-3">
                          {form_Data.ContractStatus_Code === "EXPIRED" ? (
                            <EndDate />
                          ) : null}
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-3">
                          <label>
                            Última Renovação
                          </label>
                          <div>
                            {(form_Data.CustomersContracts_StartDate===
                            form_Data.CustomersContracts_LastRenovationDate||
                            form_Data.CustomersContracts_LastRenovationDate===null)?
                            'N/A':
                            form_Data.CustomersContracts_LastRenovationDate}
                          </div>
                        </div>
                        <div className="col-3">
                          <label>
                            Próxima Renovação
                          </label>
                          <div>
                            {form_Data.CustomersContracts_NextRenovationDate}
                          </div>
                        </div>
                        <div className="col-3">
                          <label>
                            Duração do Contrato
                          </label>
                          <div>
                            {form_Data.Proposals_ContractDurationYears + (form_Data.Proposals_ContractDurationYears>1?' anos':' ano')}
                          </div>
                        </div>
                        <div className="col-3">
                          <label>
                            Valor da proposta
                          </label>
                          <div>
                            €{form_Data.Proposals_Value}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12">
                          <label htmlFor="SpecialConditions">
                            Condições Especiais
                          </label>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            name="SpecialConditions"
                            className="form-control"
                            id="Proposals_SpecialConditions"
                            value={form_Data.Proposals_SpecialConditions}
                            onChange={handleOnChange}
                          />
                        </div>
                      </div>
                      <div className="row mt-5">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <label>Meta Dados</label>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="row">
                              <div className="col-6">
                                <label>Criado por</label>
                                <div className="col-12">
                                  <AvatarUser
                                    Name={
                                      form_Data.Users_CreatedBy_FirstName +
                                      " " +
                                      form_Data.Users_CreatedBy_LastName
                                    }
                                    Base64={
                                      form_Data.UsersAvatar_CreatedBy_Base64
                                    }
                                    MimeType={
                                      form_Data.UsersAvatar_CreatedBy_MimeType
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <label>Atualizado por</label>
                                <div className="col-12">
                                  <AvatarUser
                                    Name={
                                      form_Data.Users_UpdatedBy_FirstName +
                                      " " +
                                      form_Data.Users_UpdatedBy_LastName
                                    }
                                    Base64={
                                      form_Data.UsersAvatar_UpdatedBy_Base64
                                    }
                                    MimeType={
                                      form_Data.UsersAvatar_UpdatedBy_MimeType
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-6">
                                <label>Criado em</label>
                                <div className="col-12">
                                  {moment(form_Data.CustomersContracts_CreatedAt).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </div>
                              </div>
                              <div className="col-6">
                                <label>Atualizado em</label>
                                <div className="col-12">
                                  {moment(form_Data.CustomersContracts_UpdatedAt).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </Form>
                  </div>
                  <div className="tab-pane" id="contractTab_2">
                    { form_Data.UnrelatedElevators.length>0?<ElevatorRelation />:null }
                    <div className="row">
                      <MuiDataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowId={(params) => params.ProposalElevatorRelation_Id}
                        blankStateOnlyText={true}
                        sortAsc={true}
                        sortField="Elevator_Number"
                      />
                      <Tooltip id="tooltip" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary mr-auto"
            type="submit"
            form="proposalForm"
          >
            Gravar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContractDetail;
