import { React, useState, useEffect } from "react";
import { fetchAPI } from "../../functions/fetchAPI";
import SnackbarAlert from "../SnackbarAlert";
import PopUpLoading from "../PopUpLoading";
import { Modal, Form } from "react-bootstrap";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AvatarUser from "../common/avatarUser";
import moment from "moment";
import getLocalData from "../../functions/getLocalData";
import MuiDataGrid from '../DataGrid';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import SendEmail from "../email/send_CustomerEmail";

const ProposalDetail = (props) => {

  const [loading, setLoading] = useState({
    isLoading: false,
    message: "",
  });

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    alert: "",
  });

  const [modalShow, setModalShow] = useState(false);
  const [modalEmailShow, setModalEmailShow] = useState(false);

  const [form_Data, setFormData] = useState({
    Proposal_Id: "",
    Proposal_SentDate: "",
    Proposal_ProposalDate: "",
    Proposal_ProposalExpirationDays: "",
    Proposal_Value: "",
    Proposal_ContractDurationYears: "",
    Proposal_SpecialConditions: "",
    Proposal_CreatedAt: "",
    Proposal_UpdatedAt: "",
    Proposal_CustomerId: "",
    Proposal_ContractTypeId: "",
    Proposal_StatusId: "",
    ProposalStatus_Code: "",
    Users_CreatedBy_FirstName: "",
    Users_CreatedBy_LastName: "",
    UsersAvatar_CreatedBy_Base64: "",
    UsersAvatar_CreatedBy_MimeType: "",
    Users_UpdatedBy_FirstName: "",
    Users_UpdatedBy_LastName: "",
    UsersAvatar_UpdatedBy_Base64: "",
    UsersAvatar_UpdatedBy_MimeType: "",
    Customer_Reference: "",
    Customer_Name: "",
    Elevators: [
      {
        ProposalElevatorRelation_Id: "",
        Elevator_Id: "",
        Elevator_Number: "",
        Elevator_Floors: "",
        Elevator_Load: "",
        Elevator_Speed: "",
        Manufactuer_Name: "",
        InstallationType_Name: "",
      },
    ],
    UnrelatedElevators: [
      {
        Elevator_Id: "",
        Elevator_Number: "",
      },
    ]
  });

  const [contractTypeList, setContractType] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  const [elevatorsRelationList, setElevatorsRelation] = useState([
    {
      Elevator_Id: "",
      Elevator_Number: "",
    },
  ]);

  const [selectedElevator, setSelectedElevator] = useState({
    Elevator_Id: ""
  });

  const [statusList, setStatus] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  async function getProposalDetail(id) {
    setLoading({
      isLoading: true,
      message: "A obter dados ...",
    });
    try {
      const fetchProposalDetail = await fetchAPI(
        "/api.php/proposal/detail?id=" + id,
        "GET"
      );
      const fetchContractTypes = await fetchAPI(
        "/api.php/system/contracttype/list?tenant=" +
          getLocalData("tenant", "id"),
        "GET"
      );
      const fetchStatus = await fetchAPI(
        "/api.php/proposal/listStatus", 
        "GET"
      );
      const resProposalDetail = await fetchProposalDetail;
      const resContractTypes = await fetchContractTypes;
      const resStatus = await fetchStatus;
      setFormData(resProposalDetail);
      setContractType(resContractTypes);
      setStatus(resStatus);
      setElevatorsRelation(resProposalDetail.UnrelatedElevators);
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
        type: "error",
      });
    }

    setLoading({
      isLoading: false,
      message: "",
    });
  }

  async function addElevator() {
    setLoading({
      isLoading: true,
      message: "A criar relação ...",
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchData = await fetchAPI("/api.php/proposal/addElevatorRelation", "POST", {
        Proposal_Id: props.id,
        Elevator_Id: selectedElevator.Elevator_Id,
        Customer_Id: form_Data.Proposal_CustomerId,
      });
      const res = await fetchData;
      setAlert({
        open: true,
        message: res.message,
        type: res.status,
      });
      if (res.status !== "error") {
        getProposalDetail(props.id);
      } else {
        setLoading({
          isLoading: false,
        });
      }
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
        type: "error",
      });
      setLoading({
        isLoading: false,
      });
    }
    setFormElevatorValid(false);
  }

  async function deleteElevator(id) {
    setLoading({
      isLoading: true,
      message: "A eliminar relação ...",
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchData = await fetchAPI("/api.php/proposal/deleteElevatorRelation","POST", {
        Proposal_Id: props.id,
        Elevator_Id: id,
        Customer_Id: form_Data.Proposal_CustomerId,
      });
      const res = await fetchData;
      setAlert({
        open: true,
        message: res.message,
        type: res.status,
      });
      if (res.status !== "error") {
        getProposalDetail(props.id);
      } else {
        setLoading({
          isLoading: false,
        });
      }
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
        type: "error",
      });
      setLoading({
        isLoading: false,
      });
    }
  }

  async function updateProposal() {
    setLoading({
      isLoading: true,
      message: "A atualizar a proposta ..."
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchData = await fetchAPI(
        "/api.php/proposal/update",
        "POST",
        {
          Proposal: {
            Id: form_Data.Proposal_Id,
            SentDate: form_Data.Proposal_SentDate,
            ProposalDate: form_Data.Proposal_ProposalDate,
            ProposalExpirationDays: form_Data.Proposal_ProposalExpirationDays,
            Value: form_Data.Proposal_Value,
            ContractDurationYears: form_Data.Proposal_ContractDurationYears,
            SpecialConditions: form_Data.Proposal_SpecialConditions,
            ContractTypeId: form_Data.Proposal_ContractTypeId,
            ProposalStatusId: form_Data.Proposal_StatusId,
          },
          Customers_Id: form_Data.Proposal_CustomerId,
        }
      );
      const res = await fetchData;
        setAlert({
          open: true,
          message: res.message,
          type: res.status,
        });
        if(res.status!=='error') {
          getProposalDetail(res.Id);
          props.onSave();
        } else {
          setLoading({
            isLoading: false
          });
        }
    } catch (err) {
        setAlert({
          open: true,
          message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
          type: "error",
        });
        setLoading({
          isLoading: false
        });
    }
    set_Validated(false);
  }

  async function downloadProposal(id) {
    setLoading({
      isLoading: true,
      message: "A obter documento ...",
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetch = await fetchAPI(
        "/api.php/pdf/proposal",
        "POST",
        { ProposalId: id }
      );      
      const res = await fetch;
      const a = document.createElement("a");
      a.href = `data:application/pdf;base64,${res.Base64}`;
      a.download = `${res.FileName}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      
    } catch (error) {
      console.log(error.stack);
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter o documento. Tente novamente.",
        type: "error",
      });
    }

    setLoading({
      isLoading: false,
      message: "",
    });
  }

  useEffect(() => {
    if (props.show) {
      setModalShow(true);
      getProposalDetail(props.id);
    }
  }, [props]);

  const [validated, set_Validated] = useState(false);

  const submitFn = (event) => {
    const form = event.currentTarget;

    set_Validated(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      updateProposal();
    }
  };

  const [formElevatorValid, setFormElevatorValid] = useState(false);

  const submitElevatorFn = (event) => {
    const form = event.currentTarget;

    setFormElevatorValid(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      addElevator();
    }
  };

  const handleOnChange = (event) => {
    const { id, value, selectedIndex } = event.target;

    if (id === "Proposal_StatusId" && value) {
      setFormData({
        ...form_Data,
        [id]: value,
        ProposalStatus_Code: statusList[selectedIndex - 1].Code,
      });
    } else {
      setFormData({
        ...form_Data,
        [id]: value,
      });
    }
  };

  const handleElevatorOnChange = (event) => {
    const { id, value } = event.target;
    setSelectedElevator({
      [id]: value,
    });
  };

  const columns = [
    {
      field: "Elevator_Number",
      headerName: "Elevador Nº",
      flex: 1,
    },
    {
      field: "Manufactuer_Name",
      headerName: "Fabricante",
      flex: 1,
    },
    {
      field: "InstallationType_Name",
      headerName: "Tipo de Instalação",
      flex: 1,
    },
    {
      field: "Elevator_Floors",
      headerName: "Pisos",
      flex: 1,
    },
    {
      field: "Elevator_Load",
      headerName: "Carga",
      flex: 1,
    },
    {
      field: "Elevator_Speed",
      headerName: "Velocidade m/s",
      flex: 1,
    },
    {
      field: "Actions",
      headerName: "",
      type: "actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <button
            className="btn btn-danger btn-sm ml-2"
            data-tooltip-id="tooltip"
            data-tooltip-content="Eliminar Relação"
            data-tooltip-place="top"
            onClick={() => 
              Swal.fire({
                title: "Eliminar relação",
                text: "A relação do elevador "+params.row.Elevator_Number+" com a proposta "+form_Data.Customer_Reference+" será eliminada. Pretende continuar?",
                icon: "error",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonText: "Cancelar",
                confirmButtonText: "Sim, continuar"
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteElevator(params.row.Elevator_Id);
                }
              })
            }
          >
            <i className="fas fa-trash"></i>
          </button>
        </>
      ),
    },
  ];

  const rows = form_Data.Elevators;

  const SentDate = () => (
    <>
      <label htmlFor="SentDate" className="required">
        Data de Envio
      </label>
      <Form.Control
        type="date"
        name="SentDate"
        className="form-control"
        id="Proposal_SentDate"
        value={form_Data.Proposal_SentDate}
        onChange={handleOnChange}
        required={true}
        isInvalid={!form_Data.Proposal_SentDate && validated}
      />
      <Form.Control.Feedback type="invalid">
        A Data de Envio é obrigatória!
      </Form.Control.Feedback>
    </>
  );


  const ElevatorRelation = () => (
    <>
      <Form
        noValidate
        method="post"
        onSubmit={submitElevatorFn}
      >
        <div className="row mb-5">
          <label htmlFor="ProposalElevatorRelation">
            Elevadores Disponíveis
          </label>
          <div className="col-9">
            <Form.Select
              type="select"
              as="select"
              name="ProposalElevatorRelation"
              onChange={handleElevatorOnChange}
              value={selectedElevator.Elevator_Id}
              id="Elevator_Id"
              required={true}
              isInvalid={
                !selectedElevator.Elevator_Id && formElevatorValid
              }
            >
              <option value="">Escolher Elevador</option>
              {elevatorsRelationList.length>1?
                <option value="all">Relacionar Todos</option>
              :null}
              {elevatorsRelationList.map((item, index) => (
                <option key={index} value={item.Elevator_Id}>
                  {"Elevador " + item.Elevator_Number}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              O Elevador é obrigatório!
            </Form.Control.Feedback>
          </div>
          <div className="col-3">
            <button className="btn btn-primary w-100" type="submit">
              <i className="fas fa-link pr-2"></i>
              Relacionar
            </button>
          </div>
        </div>
      </Form>
    </>
  );

  return (
    <>
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />
      <SendEmail
        show={modalEmailShow}
        referenceId={props.id}
        customerId={form_Data.Proposal_CustomerId}
        emailTemplateId="PROPSEND"
        onHide={() => {
          setModalEmailShow(false);
        }}
        onSave={() => {
          setModalEmailShow(false);
          getProposalDetail(props.id);
          props.onSave();
        }}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => {
          props.onHide();
          setModalShow(false);
        }}
        backdrop="static"
      >
        <PopUpLoading open={loading.isLoading} />
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalhes da Proposta
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid pb-4">
            <div className="row">
              <div className="col-6">
                <h4>Referência {form_Data.Customer_Reference}</h4>
              </div>
              <div className="col-6 text-right">
                <button 
                  onClick={() => {
                    downloadProposal(props.id);
                  }}
                  className="btn btn-primary btn-sm"
                  data-tooltip-id="tooltip"
                  data-tooltip-content="Descarregar Proposta"
                  data-tooltip-place="top"
                  >
                  <i className="fas fa-download"></i>
                </button>
                <button
                  className="btn btn-primary btn-sm ml-2"
                  data-tooltip-id="tooltip"
                  data-tooltip-content="Enviar email"
                  data-tooltip-place="top"
                  onClick={() => {
                    setModalEmailShow(true);
                  }}
                >
                  <i className="fas fa-envelope"></i>
                </button>
                <Tooltip id="tooltip" />
              </div>
            </div>
            <div className="card card-secondary card-outline card-outline-tabs mt-2">
              <div className="card-header p-0 border-bottom-0">
                <ul
                  className="nav nav-tabs"
                  id="custom-tabs-four-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="custom-tabs-four-home-tab"
                      data-toggle="pill"
                      href="#proposalTab_1"
                      role="tab"
                      aria-controls="proposalTab_1"
                      aria-selected="true"
                    >
                      Informação da Proposta
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-four-home-tab"
                      data-toggle="pill"
                      href="#proposalTab_2"
                      role="tab"
                      aria-controls="proposalTab_2"
                      aria-selected="true"
                    >
                      Elevadores
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content">
                  <div className="tab-pane active" id="proposalTab_1">
                    <Form
                      noValidate
                      method="post"
                      onSubmit={submitFn}
                      id="proposalForm"
                    >
                      <div className="row">
                        <div className="col-6">
                          <label htmlFor="CustomersSelect">
                            Cliente
                          </label>
                          <div id="CustomersSelect">
                          { form_Data.Customer_Reference + " - " + form_Data.Customer_Name }
                          </div>
                        </div>
                        <div className="col-6"></div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-4">
                          <label
                            htmlFor="ContractTypeSelect"
                            className="required"
                          >
                            Tipo de Contrato
                          </label>
                          <Form.Select
                            type="select"
                            as="select"
                            name="ContractTypeSelect"
                            onChange={handleOnChange}
                            value={form_Data.Proposal_ContractTypeId}
                            id="Proposal_ContractTypeId"
                            required={true}
                            isInvalid={
                              !form_Data.Proposal_ContractTypeId && validated
                            }
                          >
                            <option value="">Escolher Tipo de Contrato</option>
                            {contractTypeList.map((item, index) => (
                              <option key={index} value={item.Id}>
                                {item.Name}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            O Tipo de Contrato é obrigatório!
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-4">
                          <label htmlFor="StatusId" className="required">
                            Estado
                          </label>
                          <Form.Select
                            type="select"
                            as="select"
                            name="StatusId"
                            onChange={handleOnChange}
                            value={form_Data.Proposal_StatusId}
                            id="Proposal_StatusId"
                            required={true}
                            isInvalid={
                              !form_Data.Proposal_StatusId && validated
                            }
                          >
                            <option value="">Escolher Estado</option>
                            {statusList.map((item, index) => (
                              <option key={index} value={item.Id}>
                                {item.Name}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            O Estado é obrigatório!
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-4">
                          {form_Data.ProposalStatus_Code !== "NEW" ? (
                            <SentDate />
                          ) : null}
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-3">
                          <label htmlFor="ProposalDate" className="required">
                            Data da Proposta
                          </label>
                          <Form.Control
                            type="date"
                            name="ProposalDate"
                            className="form-control"
                            id="Proposal_ProposalDate"
                            value={form_Data.Proposal_ProposalDate}
                            onChange={handleOnChange}
                            required={true}
                            isInvalid={
                              !form_Data.Proposal_ProposalDate && validated
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            A Data da Proposta é obrigatória!
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-3">
                          <label
                            htmlFor="ProposalExpirationDays"
                            className="required"
                          >
                            Duração da Proposta (dias)
                          </label>
                          <Form.Control
                            type="number"
                            name="ProposalExpirationDays"
                            className="form-control"
                            id="Proposal_ProposalExpirationDays"
                            value={form_Data.Proposal_ProposalExpirationDays}
                            onChange={handleOnChange}
                            required={true}
                            isInvalid={
                              !form_Data.Proposal_ProposalExpirationDays &&
                              validated
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            A Duração da Proposta é obrigatória!
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-3">
                          <label htmlFor="Value" className="required">
                            Prestação Mensal
                          </label>
                          <Form.Control
                            type="number"
                            name="Value"
                            className="form-control"
                            id="Proposal_Value"
                            value={form_Data.Proposal_Value}
                            onChange={handleOnChange}
                            required={true}
                            isInvalid={!form_Data.Proposal_Value && validated}
                          />
                          <Form.Control.Feedback type="invalid">
                            A Prestação Mensal é obrigatória!
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-3">
                          <label
                            htmlFor="ContractDurationYears"
                            className="required"
                          >
                            Duração do Contrato (anos)
                          </label>
                          <Form.Control
                            type="number"
                            name="ContractDurationYears"
                            className="form-control"
                            id="Proposal_ContractDurationYears"
                            value={form_Data.Proposal_ContractDurationYears}
                            onChange={handleOnChange}
                            required={true}
                            isInvalid={
                              !form_Data.Proposal_ContractDurationYears &&
                              validated
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            A Duração do Contrato é obrigatória!
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12">
                          <label htmlFor="SpecialConditions">
                            Condições Especiais
                          </label>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            name="SpecialConditions"
                            className="form-control"
                            id="Proposal_SpecialConditions"
                            value={form_Data.Proposal_SpecialConditions}
                            onChange={handleOnChange}
                          />
                        </div>
                      </div>
                      <div className="row mt-5">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <label>Meta Dados</label>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="row">
                              <div className="col-6">
                                <label>Criado por</label>
                                <div className="col-12">
                                  <AvatarUser
                                    Name={
                                      form_Data.Users_CreatedBy_FirstName +
                                      " " +
                                      form_Data.Users_CreatedBy_LastName
                                    }
                                    Base64={
                                      form_Data.UsersAvatar_CreatedBy_Base64
                                    }
                                    MimeType={
                                      form_Data.UsersAvatar_CreatedBy_MimeType
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <label>Atualizado por</label>
                                <div className="col-12">
                                  <AvatarUser
                                    Name={
                                      form_Data.Users_UpdatedBy_FirstName +
                                      " " +
                                      form_Data.Users_UpdatedBy_LastName
                                    }
                                    Base64={
                                      form_Data.UsersAvatar_UpdatedBy_Base64
                                    }
                                    MimeType={
                                      form_Data.UsersAvatar_UpdatedBy_MimeType
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-6">
                                <label>Criado em</label>
                                <div className="col-12">
                                  {moment(form_Data.Proposal_CreatedAt).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </div>
                              </div>
                              <div className="col-6">
                                <label>Atualizado em</label>
                                <div className="col-12">
                                  {moment(form_Data.Proposal_UpdatedAt).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </Form>
                  </div>
                  <div className="tab-pane" id="proposalTab_2">
                    { form_Data.UnrelatedElevators.length>0?<ElevatorRelation />:null }
                    <div className="row">
                      <MuiDataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowId={(params) => params.ProposalElevatorRelation_Id}
                        blankStateOnlyText={true}
                        sortAsc={true}
                        sortField="Elevator_Number"
                      />
                      <Tooltip id="tooltip" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary mr-auto"
            type="submit"
            form="proposalForm"
          >
            Gravar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProposalDetail;
